/* eslint-disable camelcase */
import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HorizontalTable, CopyButton } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';
import { getRandomID, UtilsFunctions } from '@mdc/services';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import EXTRACTED_DATA from './SandboxExtractedFilesData';
import ContentLoader from 'react-content-loader';

import './SandboxExtractedFiles.scss';

const SandboxExtractedFiles = ({ isSignedIn, sandboxResources, results }) => {
    const { t, ready } = useTranslation();
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const isButtonsVisible = false;

    const [allExtractedFiles, setAllExtractedFiles] = useState({
        extracted: [],
        downloaded: []
    });
    const [buttonTooltipId] = useState(`SandboxExtractedFilesTooltip${getRandomID(5)}`);

    const activeKeys = [
        {
            key: 'extracted',
            label: t('Extracted Files')
        },
        {
            key: 'downloaded',
            label: t('Downloaded Files')
        }
    ];

    const handleExpandAll = () => {
        setIsAllExpanded(!isAllExpanded);
    };

    const sortFunction = (item1, item2) => {
        const isItem1Suspicious = item1?.suspicious;
        const isItem2Suspicious = item2?.suspicious;

        if (isItem1Suspicious === isItem2Suspicious) {
            return 0;
        } else if (isItem1Suspicious) {
            return -1;
        }
        return 1;

    };

    useEffect(() => {
        if (!sandboxResources) {
            return;
        }
        const extractedFiles = sandboxResources?.extractedFiles;
        const downloadedFiles = sandboxResources?.fileDownloadResults;

        const extractedFilesTemp = extractedFiles && extractedFiles.map((data) => {
            return ({
                overview: `/results/file/${results?.data_id}/regular/sandbox/details?select=${data?.submitName}&tab=1`,
                origin: data?.origin?.type,
                size: data?.fileSize,
                type: data?.mediaType?.string,
                downloadURL: '',
                content: '',
                md5: data?.digests?.MD5,
                sha1: (
                    <div className='sha-row'>
                        <div className='sha-value'>{data?.digests?.['SHA-1']}</div>
                        <div >
                            <CopyButton icon={'clone'} data={data?.digests?.['SHA-1']} />
                        </div>
                    </div>
                ),
                sha256: (
                    <div className='sha-row'>
                        <div className='sha-value'>{data?.digests?.['SHA-256']}</div>
                        <div >
                            <CopyButton icon={'clone'} data={data?.digests?.['SHA-256']} />
                        </div>
                    </div>
                ),
                sha512: (
                    <div className='sha-row'>
                        <div className='sha-value'>{data?.digests?.['SHA-512']}</div>
                        <div >
                            <CopyButton icon={'clone'} data={data?.digests?.['SHA-512']} />
                        </div>
                    </div>
                ),
                name: data?.submitName,
                tags: data?.allTags?.map((tag) => tag?.tag?.name),
                suspicious: data?.allTags?.filter((tag) => tag?.tag?.verdict?.threatLevel !== 0)?.length ? 'Suspicious' : null
            });
        })?.sort(sortFunction);

        const downloadedFilesTemp = downloadedFiles && downloadedFiles.map((data) => {
            return ({
                overview: `/results/file/${results?.data_id}/regular/sandbox/details?select=${data?.digests?.['SHA-256']}&tab=2`,
                origin: data?.origin?.type,
                size: data?.fileSize,
                type: data?.mediaType?.string,
                downloadURL: '',
                content: '',
                md5: data?.digests?.MD5,
                sha1: (
                    <div className="sha-row">
                        <div className="sha-value">{data?.digests?.['SHA-1']}</div>
                        <div className="sha-copy">
                            <CopyButton icon={'clone'} data={data?.digests?.['SHA-1']} label="Copy SHA1" />
                        </div>
                    </div>
                ),
                sha256: (
                    <div className="sha-row">
                        <div className="sha-value">{data?.digests?.['SHA-256']}</div>
                        <div className="sha-copy">
                            <CopyButton icon={'clone'} data={data?.digests?.['SHA-256']} label="Copy SHA256" />
                        </div>
                    </div>
                ),
                sha512: (
                    <div className="sha-row">
                        <div className="sha-value">{data?.digests?.['SHA-512']}</div>
                        <div className="sha-copy">
                            <CopyButton icon={'clone'} data={data?.digests?.['SHA-512']} label="Copy SHA512" />
                        </div>
                    </div>
                ),
                name: data?.digests?.['SHA-256'],
                tags: [data?.mediaType?.string],
                suspicious: data?.verdict?.verdict?.charAt(0)?.toUpperCase() + data?.verdict?.verdict?.slice(1)?.toLowerCase()
            });
        })?.sort(sortFunction);

        setAllExtractedFiles((preState) => ({
            ...preState,
            extracted: extractedFilesTemp || [],
            downloaded: downloadedFilesTemp || []
        }));
    }, [sandboxResources]);


    const getProcessedRawDataValue = (value) => {
        if (Array.isArray(value)) {
            return value.join(', ');
        }

        return value;
    };

    const processRawData = (data, meta) => meta.filter((row) => Object.hasOwnProperty.call(data, row.accessor) && (data[row.accessor]))
        .map((row) => {
            let value = row.parser ? row.parser(data[row.accessor]) : data[row.accessor];
            value = getProcessedRawDataValue(value);

            return { field: row.header, data: value, shouldSanitizeHTML: false };
        });

    const renderTooltip = (props, message) => (
        <Tooltip id={buttonTooltipId} {...props} className='sandboxExtractedTooltip'>
            {message}
        </Tooltip>
    );

    const generateButtonDOM = (text, handler, variant) => {
        if (!isSignedIn) {
            return <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => renderTooltip(props, 'You must be signed in')}
                trigger={'click'}
                rootClose
            >
                <div>
                    <Button variant={variant} onClick={handler}>
                        {text}
                    </Button>
                </div>
            </OverlayTrigger>;
        }

        return <Button variant='outline-primary' onClick={handler} hidden>
            {text}
        </Button>;
    };

    const expandableRowDom = (extractedData) => {
        return (
            <div className='expandableRowContainer'>
                {extractedData?.tags?.map((tag) => {
                    return <span key={tag} className='tag'>{tag}</span>;
                })}
                <HorizontalTable data={processRawData(extractedData, EXTRACTED_DATA(t))} />
                {isButtonsVisible && <div className='buttonsWrapper'>
                    {generateButtonDOM('Download', null, 'outline-primary')}
                    {generateButtonDOM('View Content', null, 'outline-primary')}
                </div>}
            </div>
        );
    };

    const expandableTableDOM = useMemo(() => (key) => {

        return allExtractedFiles[key].map((extractedData) => {
            return <ExpandableRow
                title={extractedData?.name}
                tableDom={expandableRowDom(extractedData)}
                titleSize='sm'
                className='mt-5'
                {...(extractedData?.suspicious && {
                    extraHeaderFields: [
                        <span key={extractedData?.name} className={`tag ${extractedData?.suspicious}`}>{extractedData?.suspicious?.toUpperCase()}</span>,
                        <span key={extractedData?.name} className='tag'>{extractedData?.type}</span>]
                })}
                {...(!extractedData?.suspicious && {
                    extraHeaderFields: [
                        <span key={extractedData?.name} className='tag'>{extractedData?.type}</span>
                    ]
                })}
                extraHeaderPosition={'end'}
                key={extractedData?.name}
                isExpandedByDefault={isAllExpanded}
            />;
        });
    }, [allExtractedFiles, isSignedIn, isAllExpanded]);

    const activeTabDom = useMemo(() => {
        if (!allExtractedFiles?.extracted?.length && !allExtractedFiles?.downloaded?.length) {
            return;
        }

        return activeKeys.map((item) => {
            let keyCounter = 0;
            return allExtractedFiles?.[item.key]?.length !== 0 && <Tab eventKey={item.key} title={item.label} key={keyCounter++}>
                {expandableTableDOM(item.key)}
            </Tab>;
        });
    }, [allExtractedFiles, isAllExpanded, expandableTableDOM]);

    const TabsDom = useMemo(() => {
        if (!activeTabDom) {
            const minRows = 5;
            const maxRows = 12;
            const space = 10;
            const rowHeight = 30;
            const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
            let keyCounter = 0;

            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        return <Tabs
            id="sandboxFileDetailsTab"
            className="mt-2"
        >
            {activeTabDom}
        </Tabs>;
    }, [activeTabDom]);

    if (!ready) {
        return null;
    }

    return <section className='sandboxExtractedFiles'>
        <Row>
            <Col xs={12}>
                <div className='sandboxExtractedFilesHeader'>
                    <p className='mt-4'>{t('Files embedded into content of scanned file.')}</p>
                    <Button variant='outline-secondary' onClick={handleExpandAll}>{isAllExpanded ? t('Collapse All') : t('Expand All')}</Button>
                </div>
            </Col>
        </Row>
        {TabsDom}
    </section>;
};

SandboxExtractedFiles.propTypes = {
    fileScanMockData: PropTypes.object,
    isSignedIn: PropTypes.bool,
    sandboxResources: PropTypes.object,
    results: PropTypes.object
};

export default SandboxExtractedFiles;
