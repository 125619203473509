import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import * as Sentry from '@sentry/react';
import { logService, UtilsFunctions } from '@mdc/services';

import './ErrorBoundary.scss';

const ENVIRONMENT = 'qa';
const VERSION = '1.89.1';
const GIT_HASH = 'aba449ebf';
const SENTRY_DISABLED = false;

const makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        const randomIndex = UtilsFunctions.getRandomInt(charactersLength);
        result += characters.charAt(randomIndex);
    }
    return result;
};

if (['staging', 'qa', 'prod'].includes(ENVIRONMENT)) {
    Sentry.setUser({
        username: makeId(10)
    });
    Sentry.init(
        {
            dsn: 'https://83c41db5e21141549eba1edc0989ae03@o414330.ingest.sentry.io/5305967',
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            integrations: [
                new Sentry.Replay({
                    maskAllText: true,
                    blockAllMedia: true,
                }),
            ],
            // Optional settings, see https://docs.sentry.io/platforms/javascript/guides/gatsby/config/
            environment: ENVIRONMENT,
            release: `${VERSION}-${GIT_HASH}`,
            // Ignore "ChunkLoadError" and "Not rendering React" Exception on 404 pages
            ignoreErrors: [
                /page resources for .* not found. Not rendering React/i,
                /Loading chunk .* failed/i,
                /Non-Error promise rejection captured with value.*/i,
                /Cannot read property 'disconnect' of null/i,
                /r.page is undefined/i,
                /ResizeObserver loop limit exceeded/i,
                /ResizeObserver loop completed with undelivered notifications/i,
                / can't redefine non-configurable property "getChannelData"/i
            ],
            whitelistUrls: [
                /https:\/\/mcl-staging-cdn\.opswat\.com/,
                /https:\/\/mcl-qa-cdn\.opswat\.com/,
                /https:\/\/mdcl-cdn\.opswat\.com/
            ],

            beforeSend(event, hint) {
                if (SENTRY_DISABLED) {
                    return null;
                }

                if (event.user) {
                    // Delete ip address
                    delete event.user.ip_address;
                    // Set username if there is no Identify field
                    if (!event.user.username || !event.user.email || !event.user.id) {
                        event.user.username = makeId(10);
                    }
                }

                const error = hint.originalException;

                // If network error or 404 status response, skip log
                if (error?.response?.status === 404 || error?.message === 'Network Error' || error?.response?.status === 401) {
                    return null;
                }
                return event;
            }
        }
    );
}

const ErrorBoundary = ({ children, t, showError }) => {
    const fallbackDom = useMemo(() => <Container className='ErrorBoundary'>
        <Row className="firstRow">
            <Col>
                <i className="icon-crash" />
            </Col>
        </Row>
        <Row>
            <Col>
                <h1 dangerouslySetInnerHTML={{ __html: t('Oups! It seems like the webpage crashed and we failed to recover from the error.<br/>We are sorry for the inconvenience. Please refresh your page') }} />
            </Col>
        </Row>
    </Container>, [t]);

    if (showError) {
        return fallbackDom;
    }

    return <Sentry.ErrorBoundary
        fallback={({ error }) => {
            logService.error(error);
            return fallbackDom;
        }}
    >
        {children}
    </Sentry.ErrorBoundary>;
};

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    t: PropTypes.func,
    showError: PropTypes.bool
};

export default withTranslation()(ErrorBoundary);
