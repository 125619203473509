/* eslint-disable react/prop-types */
/* eslint-disable no-useless-escape */
import { Fragment, useState, useEffect, useRef, useContext, useMemo } from 'react';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Cookie from 'js-cookie';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import Img from 'gatsby-image';
import { getLocalisedOpswatLink, InternalNavigation, TranslationFix } from '@mdc/services';
import { COOKIE_OPSWAT_REDIRECT } from '@mdc/constants';
import { Header, Footer, TextCarousel, UserContext, ScrollTopButton, CookieBanner, NetPromoterScoreManager, UploadPreloader, InternalLink } from '@mdc/ui';
import ScanComponent from '../scan-component/ScanComponent';
import FooterNavigation from '../footer-navigation/FooterNavigation';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import './Homepage.scss';


const Homepage = ({ homeImageAssets, notify, onFileDrop }) => {
    const { t, ready } = useTranslation();
    const backgroundImageRef = useRef(null);
    const [isClient, setIsClient] = useState(false);
    const userContext = useContext(UserContext);
    const direWolf = '#282828';

    const gatsbyLinks = {
        store: userContext?.data?.isOnlineStore ? <InternalLink to='/store'>{t('Store')}</InternalLink> : null,
        leaderboard: <InternalLink to='/leaderboard'>{t('Leaderboard')}</InternalLink>,
        appstore: <InternalLink to='/appstore'>{t('AppStore')}</InternalLink>
    };

    const onLicensingClick = () => {
        InternalNavigation.navigation('/licensing');
    };

    const sortAlphabetically = (a, b) => {
        if (a.node.publicURL < b.node.publicURL) { return -1; }
        if (a.node.publicURL > b.node.publicURL) { return 1; }
        return 0;
    };
    const backgrounds = homeImageAssets?.allFile?.edges.sort(sortAlphabetically);

    const getRandomInt = (max) => {
        if (typeof window !== 'undefined' && window.crypto) {
            const randArray = new Uint8Array(1);
            window.crypto.getRandomValues(randArray);
            const randByte = randArray[0];
            return Math.floor((randByte / 256) * max);
        } else {
            return Math.floor(Math.random() * max);
        }
    };

    const [random] = useState(() => getRandomInt(backgrounds?.length));
    const pickRandomBackgroundURL = () => {
        return {
            name: backgrounds ?? [random]?.node?.name,
            desktop: backgrounds ?? [random]?.node?.childImageSharp?.fluid,
        };
    };
    const getBackgroundURL = () => {
        const background = pickRandomBackgroundURL();
        return background.desktop;

    };
    const [windowLocation, setWindowLocation] = useState({ href: '/' });
    const [backgroundImage, setBackgroundImage] = useState(getBackgroundURL);

    const headerCss = {
        container: false
    };

    let backgroundClasses = {
        'backgroundRow': true
    };

    if (isClient && backgroundImageRef.current) {
        backgroundImageRef.current.selfRef.classList.remove('gatsby-image-wrapper');
        backgroundImageRef.current.selfRef.classList.add('darken');
        backgroundClasses = backgroundImageRef.current.selfRef.classList;
    }

    useEffect(() => {
        setWindowLocation(window && window.location);
        setIsClient(true);

        window.addEventListener('resize', debounce(setBackgroundImage.bind(null, getBackgroundURL), 200));
        return function cleanup() {
            window.removeEventListener('resize', debounce(setBackgroundImage.bind(null, getBackgroundURL), 200));
        };
    }, [backgroundClasses]);


    const onSignInClicked = () => {
        if (userContext.state === userContext.STATES.LOGGED_IN) {
            return;
        }

        Cookie.set(COOKIE_OPSWAT_REDIRECT, windowLocation.href, { path: '/', secure: true });
        windowLocation.href = '/login';
    };

    const fileAttacksMessage = <TranslationFix
        message={t('File-based attacks continue to be the most used method of penetrating organizations. Our “Trust no file” philosophy led us to create an advanced threat detection and prevention platform, MetaDefender Cloud, leveraging technologies like Deep Content Disarm and Reconstruction (Deep CDR), Multiscanning and Sandbox.')}
        type='p'
    />;

    const seoEnhancementContent = useMemo(() => {
        return (
            <Fragment>
                <Row className="fileAttacksWrapper">
                    <Col xs={10} lg={7} className='seoColWrapper'>
                        <h1 className="h2Mask">{t('Is your organization protected?')}</h1>
                        {fileAttacksMessage}
                    </Col>
                </Row>

                <Row className='averageCostWrapper'>
                    <Col xs={10} md={9} className='seoColWrapper'>
                        <Row className='text-md-left'>
                            <Col xs={12} md={5} className="pl-md-0 pr-md-0">
                                <h2 className="h4Mask mt-0 mb-1">
                                    <TranslationFix
                                        message={t('The average cost of a data breach in 2021 was')}
                                        type='span'
                                    />
                                    {' '}
                                    <span className="text-red">{t('$4.24 million!')}</span>
                                </h2>
                            </Col>
                            <Col xs={12} md={7} className="pr-md-0 pl-md-5">
                                <p>{t('We protect against data breaches, ransom attacks and much more by offering a comprehensive set of technologies under one cloud platform, which is accessible and easy to integrate with.')}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className='whyMetadefenderCloud'>
                    <Col xs={10} md={11} className='seoColWrapper'>
                        <h2>{t('Why MetaDefender Cloud?')}</h2>
                        <Row>
                            <Col xs={4}>
                                <p className='bigText'>150+</p>
                                <p className="grayText">{t('Deep CDR File Types')}</p>
                            </Col>
                            <Col xs={4}>
                                <p className='bigText'>{t('40B+')}</p>
                                <p className="grayText">{t('Hash Reputation Database')}</p>
                            </Col>
                            <Col xs={4}>
                                <p className='bigText'>99.6%</p>
                                <p className="grayText">{t('Malware Detection Rate')}</p>
                            </Col>
                        </Row>

                        <OutboundLink
                            href={getLocalisedOpswatLink('https://www.opswat.com/support')}
                            rel='noopener noreferrer'
                            target='_blank'
                            className="btn btn-primary"
                            type="button">
                            {t('Talk to an expert')}
                        </OutboundLink>

                    </Col>
                </Row>

                <Row className='cyberSecurityRightQuestions'>
                    <Col xs={10} md={12} className='seoColWrapper'>
                        <h2 className="mb-0">{t('Cybersecurity is all about asking the right questions.')}</h2>

                        <Row className='text-left align-items-center featureWrapper'>
                            <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 0 }}>
                                <h3 className="h4Mask">{t('Are external files verified?')}</h3>

                                <ul>
                                    <li>
                                        <p>{t('Upload and scan your file for viruses with 38 anti-malware engines')}</p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('One of the best detection rates in the world in')}{' '}
                                            <OutboundLink href='https://docs.opswat.com/mdcloud/operation/average-scan-times'
                                                rel='noopener noreferrer'
                                                target='_blank'>
                                                {t('less than 10 seconds')}
                                            </OutboundLink>
                                        </p>
                                    </li>
                                </ul>

                                <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/technologies/multiscanning')}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    className='btn btn-outline with-arrow mt-2'
                                    type='button'>
                                    {t('More about our Online File Scanner')}
                                    <i className="icon icon-arrow-right"></i>
                                </OutboundLink>
                            </Col>
                            <Col xs={{ span: 12, order: 0 }} md={{ span: 7, order: 1 }}>
                                <div className='featureImgWrapper ml-sm-auto'>
                                    <Img fluid={homeImageAssets?.multiscan?.fluid} alt='multiscan' className='img-fluid' title='Multiscan' />
                                </div>
                            </Col>
                        </Row>

                        <Row className='text-left align-items-center featureWrapper'>
                            <Col xs={12} md={7}>
                                <div className='featureImgWrapper'>
                                    <Img fluid={homeImageAssets?.scanHistory?.fluid} alt='scan history' className='img-fluid' title='Scan History' />
                                </div>
                            </Col>
                            <Col xs={12} md={5}>
                                <h3 className="h4Mask">{t('Can you check files without uploading them?')}</h3>

                                <ul>
                                    <li>
                                        <p>{t('Search for malware using file hashes (MD5, SHA1 and SHA256)')}</p>
                                    </li>
                                    <li>
                                        <p>{t('Our file reputation database has over 40 billion hashes')}</p>
                                    </li>
                                </ul>

                                <OutboundLink href='https://docs.opswat.com/mdcloud/metadefender-cloud-api-v4/ref'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    className='btn btn-outline with-arrow mt-2'
                                    type='button'>
                                    {t('More about File Reputation')}
                                    <i className="icon icon-arrow-right"></i>
                                </OutboundLink>

                            </Col>
                        </Row>

                        <Row className='text-left align-items-center featureWrapper'>
                            <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 0 }}>
                                <h3 className="h4Mask">{t('Do you open unknown documents?')}</h3>

                                <ul>
                                    <li>
                                        <p>{t('Removes embedded malware from files such as Office documents and PDFs')}</p>
                                    </li>
                                    <li>
                                        <p>{t('One of the most effective malware prevention technologies on the market')}</p>
                                    </li>
                                </ul>

                                <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/technologies/data-sanitization')}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    className='btn btn-outline with-arrow mt-2'
                                    type='button'>
                                    {t('Malware prevention with Deep CDR')}
                                    <i className="icon icon-arrow-right"></i>
                                </OutboundLink>

                            </Col>
                            <Col xs={{ span: 12, order: 0 }} md={{ span: 7, order: 1 }}>
                                <div className='featureImgWrapper ml-sm-auto'>
                                    <Img fluid={homeImageAssets?.fileInfo?.fluid} alt='fileInfo' className='img-fluid' title='File information' />
                                </div>
                            </Col>
                        </Row>

                        <Row className='text-left moreFeatures'>
                            <Col xs={12} md={4}>
                                <Col xs={12} className="pl-lg-4 pr-lg-5 d-flex flex-column justify-content-between w-100 h-100">
                                    <div className='featureImgWrapper smaller'>
                                        <Img fluid={homeImageAssets?.unknownThreats?.fluid} alt='unknown-threats' className='img-fluid' title='Unknown Threats' />
                                    </div>

                                    <div className='featureContentWrapper'>
                                        <h3 className='h4Mask'>{t('How do you stop unknown threats?')}</h3>

                                        <ul>
                                            <li>
                                                <p>{t('Detonate files in a Sandbox environment')}</p>
                                            </li>
                                            <li>
                                                <p>{t('We analyze the file behavior in search for malicious activity')}</p>
                                            </li>
                                        </ul>
                                    </div>

                                    <OutboundLink href='https://docs.opswat.com/filescan'
                                        rel='noopener noreferrer'
                                        target='_blank'
                                        className='btn btn-outline with-arrow mt-2'
                                        type='button'>
                                        {t('More about Sandbox')}
                                        <i className="icon icon-arrow-right"></i>
                                    </OutboundLink>

                                </Col>
                            </Col>
                            <Col xs={12} md={4}>
                                <Col xs={12} className="pl-lg-4 pr-lg-5 d-flex flex-column justify-content-between w-100 h-100">
                                    <div className='featureImgWrapper smaller'>
                                        <Img fluid={homeImageAssets?.threat?.fluid} alt='unknown-threats' className='img-fluid' title='Unknown Threats' />
                                    </div>

                                    <div className='featureContentWrapper'>
                                        <h3 className='h4Mask'>{t('Do you trust the URLs you open?')}</h3>

                                        <ul>
                                            <li>
                                                <p>{t('Search for URLs, domains and IP addresses')}</p>
                                            </li>
                                            <li>
                                                <p>
                                                    {t('We expose phishing websites, botnets and')}{' '}
                                                    <a href='https://docs.opswat.com/mdcloud/operation/analyzing-ips-with-metadefender-cloud' rel='noopener noreferrer' target='_blank'>{t('more')}</a>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>

                                    <OutboundLink href='https://docs.opswat.com/mdcloud/operation/safe-url-redirect'
                                        rel='noopener noreferrer'
                                        target='_blank'
                                        className='btn btn-outline with-arrow mt-2'
                                        type='button'>
                                        {t('More about Website Scanning')}
                                        <i className="icon icon-arrow-right"></i>
                                    </OutboundLink>

                                </Col>
                            </Col>
                            <Col xs={12} md={4}>
                                <Col xs={12} className="pl-lg-4 pr-lg-5 d-flex flex-column justify-content-between w-100 h-100">
                                    <div className='featureImgWrapper smaller'>
                                        <Img fluid={homeImageAssets?.vulnerability?.fluid} alt='unknown-threats' className='img-fluid' title='Unknown Threats' />
                                    </div>

                                    <div className='featureContentWrapper'>
                                        <h3 className='h4Mask'>{t('Is the software you use vulnerable to exploits?')}</h3>

                                        <ul>
                                            <li>
                                                <p>{t('We associate hashes to products, and products to CVEs')}</p>
                                            </li>
                                            <li>
                                                <p>{t('We tell you if your hash is vulnerable')}</p>
                                            </li>
                                        </ul>
                                    </div>

                                    <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/technologies/vulnerability-assessment')}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                        className='btn btn-outline with-arrow mt-2'
                                        type='button'>
                                        {t('More about our CVE Scanner')}
                                        <i className="icon icon-arrow-right"></i>
                                    </OutboundLink>

                                </Col>
                            </Col>
                        </Row>

                        <Row className='text-center'>
                            <Col>
                                <p className='h2Mask mb-md-5 mb-4'>{t('Still not convinced? Have questions?')}</p>

                                <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/contact')}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    className="btn btn-primary"
                                    type="button">
                                    {t('Get in touch')}
                                </OutboundLink>

                            </Col>
                        </Row>

                        <Row className='textLogo'>
                            <Col xs={12} md={4}>
                                <h4 className='text-md-right h5Mask text-center mb-md-0 mb-4'>{t('Over 1,500 organizations use OPSWAT for cybersecurity and compliance.')}</h4>
                            </Col>
                            <Col sm={{ span: 1, offset: 1 }} className='d-sm-flex d-none separator text-center'></Col>
                            <Col xs={12} md={{ span: 4, offset: 1 }} className='text-md-left text-center'>
                                <img src={homeImageAssets?.opswatLogo?.publicURL} alt='opswat' className='img-fluid logoWhite' title='OPSWAT logo' />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        );
    }, [t, fileAttacksMessage]);

    const content = useMemo(() => {
        return (
            <Fragment>
                <Row className='justify-content-center textCarouselWrapper'>
                    <Col
                        lg={10}
                        md={12}
                        sm={12}
                        xl={10}
                    >
                        <TextCarousel items={[
                            t('File'),
                            t('URL'),
                            t('IP address'),
                            t('Domain'),
                            t('HASH'),
                            t('CVE')
                        ]}
                        />

                        <ScanComponent
                            notify={notify}
                            onFileDrop={onFileDrop}
                            isFocused={true}
                        />
                    </Col>
                </Row>
                <Row className='infoNoteWrapper'>
                    <Col xs={{ span: 10, offset: 1 }} >
                        <div className="infoNote">
                            <div> {t('By submitting a file to MetaDefender Cloud, You give OPSWAT permission to share the results of your submission with the cybersecurity community;')}</div>
                            <span className='boldText'> {t('You consent to OPSWAT sharing any executables you submit with third-party malware exchange partners for the purpose of such partners improving their anti-malware products; ')}</span>
                            {t(' and You agree to our ')}
                            {< OutboundLink href="https://www.opswat.com/legal/free-tools-terms-of-use"
                                rel="noopener noreferrer"
                                target="_blank" >
                                {t('Terms of Service')}
                            </OutboundLink>}
                            {' '}
                            {t('and')}
                            {' '}
                            {<OutboundLink href="https://www.opswat.com/legal/privacy-policy"
                                rel="noopener noreferrer"
                                target="_blank">
                                {t('Privacy Policy')}
                            </OutboundLink>}
                            {t('. Please do not submit any personal data to OPSWAT.')}
                        </div>
                    </Col>
                </Row>
            </Fragment >
        );
    }, [t]);

    const footerNavigation = <FooterNavigation />;

    if (!ready) {
        return null;
    }

    return (
        <div className="homepage">
            <Container fluid>
                <Row>
                    <div className="contentWrapper">
                        <div className='position-relative'>
                            <BackgroundImage
                                Tag="div"
                                className={classNames(backgroundClasses)}
                                fluid={backgroundImage}
                                backgroundColor={direWolf}
                                ref={backgroundImageRef}
                            >
                            </BackgroundImage>
                            <Header
                                className={classNames(headerCss)}
                                onSignInClick={onSignInClicked}
                                onLicensingClick={onLicensingClick}
                                gatsbyLinks={gatsbyLinks}
                            />
                            <Container>
                                {content}
                            </Container>
                        </div>
                        <div className='seoEnhancementContentWrapper'>
                            <Container>
                                {seoEnhancementContent}
                            </Container>
                        </div>
                        <CookieBanner />
                        <NetPromoterScoreManager />
                        <Footer navigationItems={footerNavigation} />
                    </div>
                </Row>
            </Container>
            <UploadPreloader />
            <ScrollTopButton />
        </div>
    );

};

Homepage.propTypes = {
    notify: PropTypes.func,
    onFileDrop: PropTypes.func,
    homeImageAssets: PropTypes.any.isRequired
};

export default Homepage;

