import { useContext, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withThemeProvider, metadefenderService, logService } from '@mdc/services';
import UserContext from '../../user-context/UserContext';
import Spinner from '../../loading-spinner/loading/Loading';
import LanguageSelector from '../../main-menu/language-selector/LanguageSelector';
import { throttle } from 'lodash';
import { InternalLink, NotificationPopup } from '@mdc/ui';
import { OutboundLink } from 'gatsby-plugin-google-analytics';


import './UserMenu.scss';

const UserMenu = ({
    onSignIn,
    onShowMenuClick,
    isMenuOpen,
    themeProvider,
    onLogoutClick,
    onLicensingClick
}) => {
    const { t, ready } = useTranslation();
    const userContext = useContext(UserContext);
    const [apikeyLimits, setApikeyLimits] = useState(undefined);
    const [isLimitLoading, setIsLimitLoading] = useState(false);
    const [leading, setLeading] = useState(true);
    const [isLoggedin, setIsLoggedin] = useState(false);
    const APIKEY_LIMITS = {
        'reputation_api': t('Reputation'),
        'prevention_api': t('Prevention'),
        'sandbox_api': t('Analysis'),
        'threat_intel_search_api': t('Threat Intelligence')
    };
    const currentDate = new Date();
    const expirationDate = new Date(userContext?.data?.organization?.expiration_date);

    const getLimitsStatus = async () => {
        try {
            const [apikeyLimitsStatusPromise] = await metadefenderService.apikey.getLimitsStatus();
            const apikeyLimitStatus = await apikeyLimitsStatusPromise;

            setApikeyLimits(apikeyLimitStatus.data);
            setIsLimitLoading(false);
        } catch (error) {
            setApikeyLimits(null);
            logService.error(error);
        }

    };

    useEffect(() => {
        if (userContext?.state === userContext?.STATES.LOGGED_IN) {
            setIsLoggedin(true);
        }
    });

    const handleUserDropdownClick = throttle(async () => {
        setIsLimitLoading(true);
        await getLimitsStatus();

        if (leading) {
            setLeading(false);
        }
    }, 30000, { leading: leading, trailing: true });

    const getColorClassName = (name, remainingLimit) => {
        // Utility function to get the total limit based on the expiration date
        const getTotalLimit = (limitType) => {
            return expirationDate >= currentDate
                ? userContext?.data?.organization?.[limitType] || userContext?.data?.[limitType]
                : userContext?.data?.[limitType] || userContext?.data?.organization?.[limitType];
        };

        const totalLimitReputation = getTotalLimit('limit_reputation');
        const totalLimitPrevention = getTotalLimit('limit_prevention');
        const totalLimitSandbox = getTotalLimit('limit_sandbox');
        const totalLimitThreatIntelligence = getTotalLimit('limit_threat_intel_search');

        const getColor = (totalLimit, remainingLimitation) => {
            if (totalLimit === -1 && remainingLimitation <= 0) {
                return 'red';
            }
            if (remainingLimitation > Math.floor(totalLimit / 2)) {
                return 'green';
            } else if (remainingLimitation <= Math.floor(totalLimit / 2) && remainingLimitation > Math.floor(totalLimit / 5)) {
                return 'yellow';
            }
            return 'red';
        };

        switch (name) {
            case 'reputation_api':
                return getColor(totalLimitReputation, remainingLimit);

            case 'prevention_api':
                return getColor(totalLimitPrevention, remainingLimit);

            case 'sandbox_api':
                return getColor(totalLimitSandbox, remainingLimit);

            case 'threat_intel_search_api':
                return getColor(totalLimitThreatIntelligence, remainingLimit);

            default:
                return;
        }
    };

    const userLimitsDom = useMemo(() => {
        let liDom = null;
        if (apikeyLimits) {
            liDom = Object.keys(APIKEY_LIMITS).map((api) => {
                const remainingLimit = apikeyLimits !== undefined && !isLimitLoading ? apikeyLimits[api] : <Spinner useTheme={themeProvider.getActive()} size="14px" />;
                const limitName = APIKEY_LIMITS[api];
                const colorClassName = getColorClassName(api, remainingLimit);

                return <li key={api}>
                    <p>{limitName}</p>
                    <span className={`limit ${colorClassName}`}>{remainingLimit}</span>
                </li>;
            });
        }

        if (liDom) {
            return <>
                <NavDropdown.Divider />
                <div className='dropdown-item p-0'>
                    <ul className='userLimits'>
                        {liDom}
                    </ul>
                </div>
            </>;
        }


    }, [apikeyLimits, isLimitLoading]);

    const onSignInBtnPress = (event) => {
        event.preventDefault();
        if (!onSignIn) {
            window.location.href = '/login';
            return;
        }

        onSignIn(event.target.href);
    };

    const onLogout = (event) => {
        event.preventDefault();

        onLogoutClick();
    };

    const languageSelector = (
        <LanguageSelector selectorClassNames='d-none d-sm-block language-selector' />
    );

    const storeSelector = (
        <Nav.Link onClick={onLicensingClick} className='storeSelector'>
            <span className='storeLink'>{t('Licensing')}</span>
        </Nav.Link>
    );

    const menuSelector = (
        <Nav.Link onClick={onShowMenuClick} className='menuSelector' aria-label='Menu selector'>
            <i className={classNames({ 'icon-menu': !isMenuOpen, 'icon-close': isMenuOpen })} />
        </Nav.Link>
    );

    const loginButtonClasses = classNames({
        'loginButton': true,
        'd-block': true
    });

    const loginButton = useMemo(() => {
        if (userContext.state === userContext.STATES.PENDING) {
            return <Spinner useTheme={themeProvider.getActive()} size="14px" />;
        }

        if (userContext.state === userContext.STATES.LOGGED_OUT) {
            return (<Nav.Link
                className={loginButtonClasses}
                href="/login"
                onClick={onSignInBtnPress}
            >
                {t('Sign In')}
            </Nav.Link>);
        }

        return null;
    }, [userContext.state, loginButtonClasses, t]);

    const reputationPoints = (userContext && userContext.data && userContext.data.reputation) ? userContext.data.reputation : 0;

    const userDropdown = (
        userContext.state === userContext.STATES.LOGGED_IN ?
            (<NavDropdown
                alignRight
                className='d-block header-nav-dropdown'
                id="header-nav-dropdown"
                title={<span className='userName'>{userContext.data.firstName}</span>}
                onClick={handleUserDropdownClick}
            >
                <InternalLink className='dropdown-item' to="/account">
                    <span className="accountInfo">
                        {t('Account information')}
                    </span>
                    <span className='reputationPoints'>
                        {reputationPoints}
                        <span className='icon-award'></span>
                    </span>
                </InternalLink>
                <OutboundLink className='dropdown-item'
                    href='https://my.opswat.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    {t('My OPSWAT')}
                </OutboundLink>
                {<InternalLink className='dropdown-item' to="/account/reports/dashboard">
                    <span>
                        {t('Dashboard')}
                    </span>
                </InternalLink>}
                <InternalLink className='dropdown-item' to="/account/reports/statistics">
                    <span>
                        {t('Statistics')}
                    </span>
                </InternalLink>
                {
                    (userContext.isPaidUser || userContext?.isOrganizationAdmin) && <InternalLink className='dropdown-item' to="/account/security">
                        {t('Security')}
                    </InternalLink>
                }
                {
                    // eslint-disable-next-line camelcase
                    userContext?.data?.organization?.organization_id && userContext?.isOrganizationAdmin && <InternalLink className='dropdown-item' to="/account/settings/notifications">
                        {t('Notification Settings')}
                    </InternalLink>
                }
                <InternalLink className='dropdown-item' to="/account/submission-history">
                    {t('Submission history')}
                </InternalLink>
                <InternalLink className='dropdown-item' to="/account/vulnerability-submissions">
                    {t('Vulnerability submissions')}
                </InternalLink>
                {userContext?.data?.isOnlineStore && <InternalLink className='dropdown-item' to="/store">
                    {t('Upgrade')}
                </InternalLink>}
                {userLimitsDom}
                <NavDropdown.Divider />
                <NavDropdown.Item
                    href="/#"
                    onClick={onLogout}
                >
                    {t('Logout')}
                </NavDropdown.Item>
            </NavDropdown>) :
            null
    );

    if (!ready) {
        return null;
    }

    return (
        <div className='user-menu-items'>
            <div className='align-items-center userMenuLinks'>
                {languageSelector}
                {loginButton}
                {userDropdown}
                {storeSelector}
                {
                    // eslint-disable-next-line camelcase
                    isLoggedin && userContext?.data?.organization?.organization_id && userContext?.isOrganizationAdmin && <NotificationPopup />
                }
            </div>
            {menuSelector}
        </div>
    );
};

UserMenu.propTypes = {
    onLogoutClick: PropTypes.func.isRequired,
    onShowMenuClick: PropTypes.func.isRequired,
    isMenuOpen: PropTypes.bool,
    onLicensingClick: PropTypes.func.isRequired,
    onSignIn: PropTypes.func,
    themeProvider: PropTypes.any
};

export default withThemeProvider(UserMenu);
