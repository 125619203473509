import { CopyButton, InternalLink } from '@mdc/ui';
import { Button } from 'react-bootstrap';

const convertToButton = (data) => <Button variant='outline' onClick={data?.handleOnClick}>{data?.text}</Button>;
const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
const getOriginText = (data) => capitalizeFirstLetter(data?.split('_')?.join(' ').toLowerCase());
const convertToLink = (data, text) => <InternalLink to={data}>{text}</InternalLink>;

const convertBytes = (bytes, decimals = 2) => {
    if (!+bytes) { return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default (t) => {
    return [
        {
            header: t('Overview'),
            accessor: 'overview',
            parser: (data) => convertToLink(data, 'Extended Details')
        },
        {
            header: t('Origin'),
            accessor: 'origin',
            parser: (data) => getOriginText(data)
        },
        {
            header: t('Size'),
            accessor: 'size',
            parser: (data) => convertBytes(data)
        },
        {
            header: t('Type'),
            accessor: 'type'
        },
        {
            header: t('Download'),
            accessor: 'downloadURL',
            parser: (data) => convertToButton(data)
        },
        {
            header: t('View Content'),
            accessor: 'content',
            parser: (data) => convertToButton(data)
        },
        {
            header: t('File Magic Description'),
            accessor: 'FileMagicDescription',
            parser: (data) => getOriginText(data)
        },
        {
            header: t('Ssdeep'),
            accessor: 'ssdeep',
            parser: (data) => getOriginText(data)
        },
        {
            header: t('MD5'),
            accessor: 'md5',
        },
        {
            header: t('SHA1'),
            accessor: 'sha1',
        },
        {
            header: t('SHA256'),
            accessor: 'sha256',
        },
        {
            header: t('SHA512'),
            accessor: 'sha512',
        },
    ];
};
