import { useMemo } from 'react';
import { Tabs, Tab, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HorizontalTable, CopyButton, PlainTable } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import { UtilsFunctions, dayjs, dateTimeService } from '@mdc/services';
import MULTISCAN_DATA from '../../multiscanning/MultiscanData';
import { ENGINE_STATUS, RESULT_TEXTS } from '@mdc/constants';


import './OsintLookups.scss';


const OsintLookups = ({ sandboxResources }) => {
    const { t, ready, i18n } = useTranslation();
    const lang = i18n?.language?.split('-')[0] || 'en';
    const getHumanizedDate = (date, t, lang) => dateTimeService.getDateTimeString(date) + ' ' + t('[ {{dateHumanized}} ago ]', { dateHumanized: dateTimeService.dateHumanized(date, lang) });
    const addCopyButton = (data) => <span>{data}<CopyButton icon={'clone'} data={data} /></span>;
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    const getOriginText = (data) => {
        if (data === 'INFORMATIONAL') {
            return 'no threat';
        }
        return capitalizeFirstLetter(data?.split('_')?.join(' '));
    };
    const convertToCode = (data) => {
        return <span className='font-weight-bold font-italic'>{data}</span>;
    };
    const resultTexts = useMemo(() => RESULT_TEXTS(t), [t]);
    const activeKeys = [
        {
            key: 'MALICIOUS',
            label: t('Malicious')
        },
        {
            key: 'NO_THREAT',
            label: t('No Threat')
        },
        {
            key: 'SUSPICIOUS',
            label: t('Suspicious')
        },
        {
            key: 'UNKNOWN',
            label: t('Unknown')
        }
    ];

    const verdictParser = (data) => {
        let displayText;

        if (data === 'INFORMATIONAL') {
            displayText = 'NO THREAT';
        } else if (data === 'LIKELY_MALICIOUS') {
            displayText = 'MALICIOUS';
        } else {
            displayText = data;
        }

        return (
            <span key={data} className={`badge ${data}`}>
                {displayText}
            </span>
        );
    };

    const tableColumns = [
        {
            header: t('Type'),
            accessor: 'type',
            parser: (data) => getOriginText(data)
        },
        {
            header: t('Origin'),
            accessor: 'origin.type',
            parser: (data) => getOriginText(data)
        },
        {
            header: t('Verdict'),
            accessor: 'verdict',
            parser: (data) => verdictParser(data)

        },
        {
            header: t('SHA1'),
            accessor: 'data.file_info.sha1',
            parser: (data) => addCopyButton(data)
        },
        {
            header: t('MD5'),
            accessor: 'data.file_info.md5',
            parser: (data) => addCopyButton(data)
        },
        {
            header: t('Scan date'),
            accessor: 'data.last_start_time',
            parser: (data) => getHumanizedDate(data, t, lang)
        },
        {
            header: t('Malware family'),
            accessor: 'data.malware_family',
            parser: (data) => convertToCode(data)
        },
    ];

    function processRawData(lookup, tableColumns) {
        const processedData = [];

        tableColumns.forEach((column) => {
            const { header, accessor, parser } = column;
            const keys = accessor.split('.');
            let value = lookup;
            for (const key of keys) {
                if (value == null) {
                    value = undefined;
                    break;
                }
                value = value[key];
            }
            if (parser && value && typeof parser === 'function') {
                value = parser(value);
            }
            processedData.push({ field: header, data: value || '-', shouldSanitizeHTML: false });
        });

        return processedData;
    }


    const getThreatDetails = (scanResult, resultTexts, t) => {
        let threatName, threatClass, iconClass, sorting;

        switch (scanResult) {
            case ENGINE_STATUS.INFECTED:
                threatName = scanResult?.threat_found?.trim() || resultTexts.resultStatus.unknown_threat;
                threatClass = 'detected';
                iconClass = 'icon-close';
                sorting = 0;
                break;

            case ENGINE_STATUS.SUSPICIOUS:
                threatName = t('Suspicious');
                threatClass = 'suspicious';
                iconClass = 'icon-warning-empty';
                sorting = 1;
                break;

            case ENGINE_STATUS.NO_THREAT:
                threatName = t('No threat detected');
                threatClass = '';
                iconClass = 'icon-checkmark-symbol';
                sorting = 2;
                break;

            case ENGINE_STATUS.UNSUPPORTED_FILE_TYPE:
                threatName = t('Unsupported file type');
                threatClass = 'suspicious';
                iconClass = 'icon-warning-empty';
                sorting = 3;
                break;

            default:
                threatName = Object.hasOwn(resultTexts.resultStatus, `i${scanResult}`)
                    ? resultTexts.resultStatus[`i${scanResult}`]
                    : 'No threat detected';
                threatClass = '';
                iconClass = 'icon-checkmark-symbol';
                sorting = 2;
                break;
        }

        return { threatName, threatClass, iconClass, sorting };
    };

    const formatLastUpdate = (defTime) => {
        if (!defTime || new Date(defTime).getTime() === 0) {
            return '-';
        }
        return dayjs(defTime).format('ll');
    };

    const createTableData = (scanDetails, resultTexts, t, i18n) => {
        return Object.keys(scanDetails || {}).map((engineName) => {
            const scanResult = scanDetails[engineName]?.scan_result_i;
            const { threatName, threatClass, iconClass, sorting } = getThreatDetails(scanResult, resultTexts, t);

            dayjs.locale(i18n?.language?.split('-')[0] || 'en');

            return {
                result: (<span className={threatClass}><i className={`icon ${iconClass}`} />{threatName}</span>),
                engine: (<span className={threatClass}>{engineName}</span>),
                lastUpdate: formatLastUpdate(scanDetails[engineName]?.def_time),
                sorting
            };
        });
    };

    const sortScanData = (scan1, scan2) => UtilsFunctions.sortHelper(scan1.sorting, scan2.sorting);

    const activeTabDom = useMemo(() => {
        if (!sandboxResources?.results) {
            return;
        }

        return activeKeys?.map((item) => {
            let keyCounter = 0;
            const tabsContent = sandboxResources?.results?.map((lookup) => {
                const verdict = lookup.verdict === 'LIKELY_MALICIOUS' ? 'MALICIOUS' : lookup.verdict;
                const scanDetails = lookup?.data?.scan_results?.scan_details;

                const tableData = createTableData(scanDetails, resultTexts, t, i18n);
                tableData.sort(sortScanData);

                return verdict === item.key && (
                    <ExpandableRow
                        title={addCopyButton(lookup?.resource)}
                        tableDom={
                            <>
                                <HorizontalTable data={processRawData(lookup, tableColumns)} />
                                {scanDetails &&
                                    <>
                                        <Row className='separator'>
                                            <div></div>
                                        </Row>
                                        <PlainTable
                                            columnsData={MULTISCAN_DATA}
                                            data={tableData}
                                            hasSorting={true}
                                        />
                                    </>
                                }
                            </>
                        }
                        titleSize='sm'
                        className='mt-5'
                        {...({
                            extraHeaderFields: [
                                <span key={lookup?.resource} className={`badge ${verdict}`}>{verdict === 'INFORMATIONAL' ? 'NO THREAT' : verdict}</span>,
                            ]
                        })}
                        extraHeaderPosition={'end'}
                        key={lookup?.resource}
                    />
                );
            }).filter(Boolean);

            if (tabsContent.length > 0) {
                return <Tab eventKey={item.key} title={item.label} key={keyCounter++}>
                    {tabsContent}
                </Tab>;
            }
            return null;
        }).filter(Boolean);
    }, [sandboxResources, activeKeys, MULTISCAN_DATA, tableColumns, resultTexts, t, i18n]);




    const tabsDom = useMemo(() => {
        if (!activeTabDom) {
            const minRows = 5;
            const maxRows = 12;
            const space = 10;
            const rowHeight = 30;
            const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
            let keyCounter = 0;

            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        return <Tabs
            id="sandboxFileDetailsTab"
            className="mt-2"
        >
            {activeTabDom}
        </Tabs>;
    }, [activeTabDom]);

    if (!ready) {
        return null;
    }

    return <section className='osint'>
        <p className='h6Mask'>{t('Open Source Intelligence Lookups')}</p>
        <p className='mt-4 pb-4'>{t('The input SHA-256 and other selected IOCs are checked against online reputation services for threat detection.')}</p>
        {tabsDom}
    </section>;
};

OsintLookups.propTypes = {
    sandboxResources: PropTypes.object,
    sandboxResults: PropTypes.object
};

export default OsintLookups;
