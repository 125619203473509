import { useMemo, useContext } from 'react';
import Layout from '../../components/layouts/freshest/FreshestLayout';
import PropTypes from 'prop-types';
import { Licensing, InternalLink, account } from '@mdc/ui';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getLocalisedOpswatLink } from '@mdc/services';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { UserContext } from '@mdc/ui';

import plans from '@mdc/ui/src/licensing/plans.data';
import faqs from '@mdc/ui/src/licensing/faqs.data';

const { AccountBanner } = account;

const LicensingTemplate = ({ data }) => {
    const { t, ready } = useTranslation();
    const enginesData = {
        'Lionic': data.lionic.fluid,
        'AhnLab': data.anhLab.fluid,
        'Avira': data.avira.fluid,
        'Bitdefender': data.bitDefender.fluid,
        'ClamAV': data.clamAv.fluid,
        'Comodo Licensing': data.comodoLicensing.fluid,
        'CrowdStrike Falcon ML': data.crowdStrikeFalconMl.fluid,
        'Varist': data.varist.fluid,
        'Emsisoft': data.emsiSoft.fluid,
        'Filseclab': data.filsecLab.fluid,
        'IKARUS': data.ikarus.fluid,
        'K7': data.k7.fluid,
        'McAfee': data.mcafee.fluid,
        'NANOAV': data.nanoAv.fluid,
        'Quick Heal': data.quickHeal.fluid,
        'RocketCyber': data.rocketCyber.fluid,
        'tachyon': data.tachyon.fluid,
        'VirItExplorer': data.virItExplorer.fluid,
        'VirIt': data.virIt.fluid,
        'WebRoot': data.webRoot.fluid,
        'Microsoft Defender': data.windowsDefender.fluid,
        'Xvirus Anti-Malware': data.xVirusPersonalGuard.fluid,
        'Zillya!': data.zillya.fluid,
        'CMC': data.cmc.fluid
    };

    const sortedEnginesList = Object.keys(enginesData).sort((enginesA, enginesB) => {
        return enginesA.toUpperCase().localeCompare(enginesB.toUpperCase());
    });

    const userContext = useContext(UserContext);

    const domEnginesList = (
        <Row className="enginesList">{
            sortedEnginesList.map((engine) => (
                <Col key={engine} xs={6} sm={3} md={2}>
                    <Col xs={12}>
                        <Img
                            className='mdc-image-wrapper'
                            fluid={enginesData[engine]}
                            alt={engine}
                            title={engine}
                        />
                    </Col>
                </Col>
            ))
        }</Row>
    );

    const planButtons = {
        'standard': userContext?.data?.isOnlineStore === true
            ? <InternalLink to='/store'>{t('Buy now')}</InternalLink>
            : (userContext?.data?.isOnlineStore === false || userContext?.data?.isOnlineStore === undefined) && userContext.state != "pending"
                ? <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/contact')} rel="noopener noreferrer" target="_blank">{t('Request an evaluation')}</OutboundLink>
                : null,
        'professional': <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/contact')} rel="noopener noreferrer" target="_blank">{t('Request an evaluation')}</OutboundLink>,
        'enterprise': <OutboundLink href={getLocalisedOpswatLink('https://www.opswat.com/contact')} rel="noopener noreferrer" target="_blank">{t('Request an evaluation')}</OutboundLink>
    };


    const licensing = useMemo(() => {
        return <Licensing plans={plans(t)} faqs={faqs} domEnginesList={domEnginesList} licensingIcon={data.licensingIcon.publicURL} planButtons={planButtons} />;
    }, [faqs, domEnginesList, planButtons, t]);


    const bannerDom = <Row className='banner-text'>
        <Col className='first-sentence'>
            {t('By submitting a file to MetaDefender Cloud you are giving OPSWAT permission to share the results of your submission with the cyber security community and you are agreeing to our ')}
            {<OutboundLink href="https://www.opswat.com/legal/free-tools-terms-of-use"
                rel="noopener noreferrer"
                target="_blank">
                {t('Terms of Service')}
            </OutboundLink>}
            {' '}
            {t('and')}
            {' '}
            {<OutboundLink href="https://www.opswat.com/legal/privacy-policy"
                rel="noopener noreferrer"
                target="_blank">
                {t('Privacy Policy ')}
            </OutboundLink>}
        </Col>
    </Row>;

    if (!ready) {
        return;
    }

    return (
        <Layout pageTitle="Licensing" className='licensingTemplate' pageDescription={'MetaDefender Cloud licensing plan overview'}>
            <AccountBanner contentDom={bannerDom} style={{ marginTop: '20px' }} />
            {licensing}
        </Layout>
    );
};

LicensingTemplate.propTypes = {
    data: PropTypes.object
};

export default LicensingTemplate;

export const engineImages = graphql`
query engineImages {
    lionic: imageSharp(resize: {originalName: {eq: "lionic.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    anhLab: imageSharp(resize: {originalName: {eq: "ahn-lab.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    avira: imageSharp(resize: {originalName: {eq: "avira.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    bitDefender: imageSharp(resize: {originalName: {eq: "bitdefender.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    clamAv: imageSharp(resize: {originalName: {eq: "clamav.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    comodoLicensing: imageSharp(resize: {originalName: {eq: "comodo-licensing.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    crowdStrikeFalconMl: imageSharp(resize: {originalName: {eq: "crowdstrikefalconml.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    varist: imageSharp(resize: {originalName: {eq: "varist1.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    emsiSoft: imageSharp(resize: {originalName: {eq: "emsisoft.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    filsecLab: imageSharp(resize: {originalName: {eq: "filseclab.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    ikarus: imageSharp(resize: {originalName: {eq: "ikarus.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    kaspersky: imageSharp(resize: {originalName: {eq: "kaspersky.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    k7: imageSharp(resize: {originalName: {eq: "k7.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    mcafee: imageSharp(resize: {originalName: {eq: "mcafee.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    webRoot: imageSharp(resize: {originalName: {eq: "webroot.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    windowsDefender: imageSharp(resize: {originalName: {eq: "windowsdefender.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    nanoAv: imageSharp(resize: {originalName: {eq: "nanoav.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    quickHeal: imageSharp(resize: {originalName: {eq: "quickheal.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    rocketCyber: imageSharp(resize: {originalName: {eq: "rocketcyber.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    tachyon: imageSharp(resize: {originalName: {eq: "tachyon.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    virItExplorer: imageSharp(resize: {originalName: {eq: "viritexplorer.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    virIt: imageSharp(resize: {originalName: {eq: "virit.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    xVirusPersonalGuard: imageSharp(resize: {originalName: {eq: "xviruspersonalguard.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    zillya: imageSharp(resize: {originalName: {eq: "zillya.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    cmc: imageSharp(resize: {originalName: {eq: "cmc.png"}}) {
        fluid(quality: 100, maxWidth: 100) {
            ...GatsbyImageSharpFluid
        }
    }
    licensingIcon: file(relativePath: {eq: "icons/licensing.svg"}) {
        publicURL
    }
  }
`;
