/* eslint-disable no-useless-escape */
import { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LOOKUP_TYPES, ROUTES, ERRORS, NOTIFICATION_TYPES, UPLOAD_PRELOADER_STATES, SCAN_STATES, COOKIE_ALREADY_USED } from '@mdc/constants';
import { ScanInput, UserContext } from '@mdc/ui';
import { withUploadPreloaderProvider, FileScanService, Base64, InternalNavigation, dayjs } from '@mdc/services';
import Cookies from 'js-cookie';

import './ScanComponent.scss';


const ScanComponent = (props) => {
    const { t, ready } = useTranslation();
    const { notify, onFileDrop, uploadProvider, showText, scanError, isFocused } = props;
    const userContext = useContext(UserContext);
    const isError = useMemo(() => scanError?.status && scanError?.status?.toString().match(/^(?:5)\d+$/) ? true : false, [scanError]);
    const [windowLocation, setWindowLocation] = useState({ href: '/' });

    const isUserLogin = useMemo(() => userContext.state === userContext.STATES.LOGGED_IN, [userContext]);
    const isNotOrgExpired = useMemo(() => { return isUserLogin && dayjs(userContext.data?.organization?.expiration_date).isAfter(dayjs()); }, [userContext, isUserLogin]);

    // eslint-disable-next-line camelcase
    const maximumFileSize = useMemo(() => isUserLogin && (isNotOrgExpired && userContext.data?.organization?.max_upload_file_size) || userContext.data?.max_upload_file_size, [userContext.state, isUserLogin, isNotOrgExpired]);

    useEffect(() => {
        setWindowLocation(window && window.location);
    }, []);

    const handleOnFileUpload = useMemo(() => {
        const notifySizeExceeded = () => notify({
            message: t('Please select a file less than {{maxSize}}MB', { maxSize: maximumFileSize || '750' }),
            type: NOTIFICATION_TYPES.WARNING
        });

        const notifyNetworkError = () => notify({
            message: t('Network error occurred, and your request could not be completed. Please refresh the page'),
            type: NOTIFICATION_TYPES.CRITICAL
        });

        const notifyFileEmpty = () => notify({
            message: t('The file you selected is empty'),
            type: NOTIFICATION_TYPES.CRITICAL
        });

        const notifyDefaultError = (error) => notify({
            message: error,
            type: NOTIFICATION_TYPES.CRITICAL
        });

        const handleLimitError = (headers) => {
            const limitForMapping = {
                'sandbox_api': 'Sandbox API',
                'reputation_api': 'Reputation API',
                'prevention_api': 'Prevention API',
                'threat_intel_search_api': 'Threat Intelligence API'
            };

            const limitFor = headers['x-ratelimit-for'];
            const limit = headers['x-ratelimit-limit'];
            const limitForText = limitForMapping[limitFor] || 'undefined service';

            const message = userContext?.data?.isOnlineStore
                ? t('You have reached your account limit of ({{limit}} requests/day for the {{limitFor}}). Please go to our <a href=\'/store\'>online store</a> to extend your limits or contact our <a href=\'https://www.opswat.com/contact\'>sales team</a>', { limitFor: limitForText, limit: limit })
                : t('You have reached your account limit of ({{limit}} requests/day for the {{limitFor}}). Please contact our <a href=\'https://www.opswat.com/contact\'>sales team</a>', { limitFor: limitForText, limit: limit });

            notify({
                message,
                type: NOTIFICATION_TYPES.CRITICAL
            });
        };

        const handleError = (error, headers) => {
            const errorHandlers = {
                [ERRORS.FILE_SIZE_EXCEEDED]: notifySizeExceeded,
                [ERRORS.NETWORK_ERROR]: notifyNetworkError,
                [ERRORS.FILE_EMPTY]: notifyFileEmpty,
                [ERRORS.LIMIT_ERROR]: () => handleLimitError(headers),
                'default': () => notifyDefaultError(error),
            };

            (errorHandlers[error] || errorHandlers['default'])();
            uploadProvider.setPreloaderState(UPLOAD_PRELOADER_STATES.INACTIVE);
        };

        const setPreloaderState = (text) => {
            uploadProvider.setPreloaderState(UPLOAD_PRELOADER_STATES.IN_PROGRESS);
            uploadProvider.setPreloaderText(t(text));
        };

        const resetPreloaderState = () => {
            uploadProvider.setPreloaderState(UPLOAD_PRELOADER_STATES.INACTIVE);
            uploadProvider.setPreloaderText('');
        };

        const handleNavigationAndCookies = (hash, data_id) => {
            if (hash) {
                InternalNavigation.navigation(`${ROUTES.results.href}/file/${hash}/hash/overview`);
            }
            if (data_id) {
                InternalNavigation.navigation(`${ROUTES.results.href}/file/${data_id}/regular/overview`);
            }
            if (hash || data_id) {
                Cookies.set(COOKIE_ALREADY_USED, true, { secure: true, sameSite: 'Strict' });
            }
        };

        const handleProgress = (status, { hash, percent, data_id }) => {
            switch (status) {
                case SCAN_STATES.CALCULATE_HASH:
                    setPreloaderState('Calculating the hash');
                    break;
                case SCAN_STATES.SEARCH_HASH:
                    setPreloaderState('Checking for existing results');
                    break;
                case SCAN_STATES.FOUND_HASH:
                    setPreloaderState('Found results');
                    setTimeout(resetPreloaderState, 1500);
                    break;
                case SCAN_STATES.UPLOAD:
                    setPreloaderState(`Uploading file (${percent}%)`);
                    break;
                case SCAN_STATES.UPLOAD_COMPLETE:
                    setPreloaderState('Successfully uploaded');
                    setTimeout(resetPreloaderState, 1500);
                    break;
            }

            handleNavigationAndCookies(hash, data_id);
        };

        return (files) => {
            if (!files?.length) {
                return;
            }

            const fileScanService = new FileScanService(files[0], maximumFileSize);
            fileScanService.onError(handleError);
            fileScanService.onProgress(handleProgress);
            uploadProvider.setPreloaderState(UPLOAD_PRELOADER_STATES.IN_PROGRESS);
            fileScanService.scan(isUserLogin);
        };
    }, [maximumFileSize, isUserLogin]);

    useEffect(() => {
        onFileDrop(handleOnFileUpload, 'handleOnFileUpload_callback_Id');
    }, [handleOnFileUpload]);

    const onProcess = (lookupType, term) => {
        let encodedTerm;
        if (!lookupType) {
            return;
        }

        Cookies.set(COOKIE_ALREADY_USED, true, { secure: true, sameSite: 'Strict' });

        switch (lookupType) {
            case LOOKUP_TYPES.CVE:
                InternalNavigation.navigation(`${ROUTES.vulnerabilities}/${term}`, { state: { isError } });
                break;
            case LOOKUP_TYPES.DOMAIN:
                encodedTerm = Base64.encode(term.replace(/\/$/, ''));
                InternalNavigation.navigation(`${ROUTES.results.href}${ROUTES.results.domain}/${encodedTerm}/overview`, { state: { isError } });
                break;
            case LOOKUP_TYPES.URL:
                encodedTerm = Base64.encode(term.replace(/\/$/, ''));
                InternalNavigation.navigation(`${ROUTES.results.href}${ROUTES.results.url}/${encodedTerm}/overview`, { state: { isError } });
                break;
            case LOOKUP_TYPES.HASH:
                InternalNavigation.navigation(`${ROUTES.results.href}${ROUTES.results.file}/${term}/hash/overview`, { state: { isError } });
                break;
            case LOOKUP_TYPES.IP:
                encodedTerm = Base64.encode(term);
                InternalNavigation.navigation(`${ROUTES.results.href}${ROUTES.results.ip}/${encodedTerm}/overview`, { state: { isError } });
                break;
            default:
                encodedTerm = Base64.encode(term);
                windowLocation.href = '/';
        }
    };

    const onProcessError = (error) => {
        if (error === ERRORS.INVALID_SEARCH_TERM) {
            notify({
                message: t('Invalid search term. Please check to make sure you entered the correct information'),
                type: NOTIFICATION_TYPES.WARNING
            });
        }

        if (error === ERRORS.INVALID_IP) {
            notify({
                message: t('Please enter a valid public IP address'),
                type: NOTIFICATION_TYPES.WARNING
            });
        }
    };

    const placeholder = props.placeholder ? props.placeholder : t('File, URL, IP address, Domain, Hash, or CVE');
    const onFileUpload = props.onFileUpload ? props.onFileUpload : handleOnFileUpload;
    const onProcessCallback = props.onProcess ? props.onProcess : onProcess;
    const onProcessErrorCallback = props.onProcessError ? props.onProcessError : onProcessError;

    const trustNoText = showText ? (
        <h2 className='trustNoText'>
            {t('Trust no')}
        </h2>
    ) : null;

    const trustNoTextSm = showText ? (
        <div className='trustNoTextSm'>
            <p dangerouslySetInnerHTML={{ __html: t('Trust No File.<br/>Trust No Device') }} />
        </div>
    ) : null;

    if (!ready) {
        return null;
    }

    return (
        <div className='scanInputComponent'>
            {trustNoTextSm}
            <div className='scanInputWrapper'>
                {trustNoText}
                <div className='processWrapper'>
                    <ScanInput
                        advancedOptionsList={props.advancedOptionsList}
                        onFileDrop={onFileDrop}
                        onFileUpload={onFileUpload}
                        onProcess={onProcessCallback}
                        onProcessError={onProcessErrorCallback}
                        placeholder={placeholder}
                        showText={props.showText}
                        isFocused={isFocused}
                    />
                </div>
            </div>
        </div>
    );
};

export default withUploadPreloaderProvider(ScanComponent);

ScanComponent.defaultProps = {
    showText: true
};

ScanComponent.propTypes = {
    advancedOptionsList: PropTypes.array,
    notify: PropTypes.func.isRequired,
    onFileDrop: PropTypes.func.isRequired,
    onFileUpload: PropTypes.func,
    onProcess: PropTypes.func,
    onProcessError: PropTypes.func,
    placeholder: PropTypes.string,
    showText: PropTypes.bool,
    uploadProvider: PropTypes.any,
    scanError: PropTypes.any,
    isFocused: PropTypes.bool
};
